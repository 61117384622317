import {adminRole, agencies, managerRole, userRole} from "./module_components/Functions/global";
import CustomerPageContainer from "./pages/Basic/Customer/CustomerPage/CustomerPage";
import ContractHandlerContainer from "./module_components/Pages/Contracts/ContractHandler";
import ClearingTableContainer from "./pages/Basic/Clearing/ClearingTable";
import LoginContainer from "./pages/Guest/Login/Login";
import SetNewPasswordContainer from "./pages/Guest/SetNewPassword/SetNewPassword";
import PasswordRecoveryContainer from "./pages/Guest/PasswordRecovery/PasswordRecovery";
import RegisterContainer from "./pages/Guest/Register/Register";
import ContractSignedSuccess from "./pages/Guest/ContractSignedSuccess/ContractSignedSuccess";
import PageNotFound from "./pages/PageNotFound";

const {rheingas} = agencies;

/* URLS Consultant Project */
export const CalculatorDataCustomer = '/kalkulator/daten/:customer_id';
export const CalculatorComparision = '/kalkulator/vergleich/:token';
export const CalculatorContractData = '/kalkulator/vertragsdaten/:token';
export const CalculatorAttachedData = '/kalkulator/anschlussdaten/:token';
export const CalculatorVerification = '/kalkulator/ueberpruefung/:token';

/* URLS EVU-Login */
export const DashboardPath = "/dashboard";
export const ContractsPath = "/auftraege";
export const SingleContract = "/auftrag";
export const Account = "/konto";
export const LoginPath = "/login";
export const PasswordRecoveryPath = "/passwort-zuruecksetzen";
export const SetNewPasswordPath = "/neues-passwort";
export const ClearingList = "/aufgaben-liste";
export const RegisterPath = "/registrieren";
export const UserManagement = "/nutzerverwaltung";
export const Marginsettings = "/provisionsvorgaben";
export const Integrations = "/integrationen";
export const notentifications = "/benachrichtigungen";
export const ActiveIntegrations = "/aktive-integrationen";
export const ConsumptionpointPath = "/lieferstelle";
export const tariff_list = "/tarife";
export const pfc_list = "/pfcs";
export const global_criteria = "/globale-kriterien";
export const guide_lines = "/richtlinien";
export const handleTariff = "/tarif";
export const newTariff = "/0";
export const Pricestrategy = "/preisstrategie";
export const TariffDocs = "/tarif-dokumente";
export const TariffStatus = "/tarif-status";
export const CompanyData = "/firmenprofil";
export const UserProfile = "/nutzerprofil";
export const firstPageUrl = "/auftraege";

/* URLS */
export const Contacts = "/kontakte";
export const Contact = "/kontakt";
export const Contactoverview = "/kontaktansicht";
export const ConsumptionPoint = "/lieferstelle";
export const ConsumptionPointClearing = "/lieferstelle-vertrag";
export const Provisions = '/provisionen';
export const Calculation = '/kalkulation';
export const Calculator = '/kalkulator';
export const Data = '/daten';
export const Compare = '/vergleich';
export const ContractData = '/vertragsdaten';
export const AttachedDataPath = '/anschlussdaten';
export const Verification = '/ueberpruefung';
export const Billing = '/abrechnung';
export const Profile = '/profil';
export const Contracts = "/auftraege";
export const Customized = "/customize";
export const Support = "/support";
export const SuccessPage = '/vertragsabschluss';
export const Notifications = '/benachrichtigungen';
export const Messages = '/nachrichten';

/* Wattline */
export const rootUrl = '/';
export const Index = '/lastgang-hochladen';
export const Teams = "/teams";
export const TeamView = "/team";
export const Bundling = {
    path: "/buendelung",
    team: "Vertrieb"
};
export const Global = "/global";
export const Electricity = "/strom";
export const Gas = "/gas";
export const Customers = "/kunden";
export const CalculationUnits = "/kalkulationseinheiten";
export const CalculationUnit = "/kalkulationseinheit";
export const Tasks = "/aufgaben";
export const MyTasks = "/meine-aufgaben";
export const Task = "/aufgabe";
export const CustomerView = "/kunde";
export const PermissionDeniedPage = "/zugriff-verweigert";

const project_name = process.env.REACT_APP_PROJECT_NAME;

const wattline_url = ["WATTLINE_PROJECT"];
const consultant_url = ["CONSULTANT_PROJECT"];
const evu_url = ["EVU_PROJECT"];
const wattline_evu_url = ["WATTLINE_PROJECT", "EVU_PROJECT"];
const wattline_consultant_url = ["WATTLINE_PROJECT", "CONSULTANT_PROJECT"];
const wattline_consultant_evu_url = ["WATTLINE_PROJECT", "CONSULTANT_PROJECT", "EVU_PROJECT"];
const evu_consultant_url = ["EVU_PROJECT", "CONSULTANT_PROJECT"];

export const teams = ['Intern', 'Extern'];

export const sidebar_sections = {
    default: {
        collapse: false,
        title: "", // title
        permissions: [adminRole, managerRole, userRole]
    },
    calculation: {
        title: "Vertrieb", // title
        separator: "my-3",
        permissions: [adminRole, managerRole, userRole]
    },
    management: {
        title: "Verwaltung", // title
        separator: "my-3",
        permissions: [adminRole, managerRole, userRole]
    },
    administration: {
        title: "Administration", // title
        separator: "my-3",
        permissions: [adminRole]
    }
};

//NOTE: URLs with optional /:param should be defined before the sidebar because the url without /:param will
export const all_urls = [
    /* DEFAULT URLS */
    {
        title: 'Kontakt Ansicht',
        path: Contactoverview+'/:id',
        component: import("pages/Basic/Customer/CustomerPage/CustomerPage"),
        permissions: [adminRole, managerRole, userRole]
    },
    {
        title: 'Kontakt Formular',
        path: Contact+'/:id',
        component: import("pages/Basic/Customer/CustomerForm/CustomerFormContainer"),
        permissions: [adminRole, managerRole, userRole]
    },
    {
        title: 'Lieferstelle Formular',
        path: ConsumptionPoint+'/:medium_type/:customer_id/:consumption_point_id',
        component: import("pages/Basic/Consumptionpoint/ConsumptionPointHandler"),
        permissions: [adminRole, managerRole, userRole]
    },
    {
        title: 'Kalkulator Vorausgewählt',
        path: Calculator+Data+'/:customer_id?',
        component: import("module_components/Pages/Calculation/Pages/CalculationData"),
        permissions: [adminRole, managerRole, userRole]
    },
    {
        path: ConsumptionPointClearing + "/:contract_id/:consumption_point_id/:protocol_id?",
        component: ContractHandlerContainer,
        permissions: [adminRole, managerRole, userRole]
    },
    {
        title: 'Kalkulator Tarifvergleich',
        path: Calculator+Compare+'/:token',
        component: import("module_components/Pages/Calculation/Pages/CalculationHandler"),
        permissions: [adminRole, managerRole, userRole]
    },
    {
        title: 'Kalkulator Vertragsdaten',
        path: Calculator+ContractData+'/:token',
        component: import("module_components/Pages/Calculation/Pages/CalculationHandler"),
        permissions: [adminRole, managerRole, userRole]
    },
    {
        title: 'Kalkulator Anschlussdaten',
        path: Calculator+AttachedDataPath+'/:token',
        component: import("module_components/Pages/Calculation/Pages/CalculationHandler"),
        permissions: [adminRole, managerRole, userRole]
    },
    {
        title: 'Kalkulator Überprüfung',
        path: Calculator+Verification+'/:token',
        component: import("module_components/Pages/Calculation/Pages/CalculationHandler"),
        permissions: [adminRole, managerRole, userRole]
    },
    //     title: 'Kalkulator Überprüfung',
    //     path: Calculator+Verification+'/:token',
    //     component: import("module_components/Pages/Calculation/Pages/CalculationHandler"),
    //     permissions: [adminRole, managerRole, userRole]
    // },
    {
        title: 'Vertrag',
        path: SingleContract+'/:contract_id:/protocol_id',
        component: import("module_components/Pages/Contracts/ContractHandler"),
        permissions: [adminRole, managerRole, userRole]
    },
    {
        title: 'Benachrichtigungen',
        path: Notifications,
        component: import("module_components/Pages/Notifications/NotificationTable"),
        permissions: [adminRole, managerRole, userRole]
    },
    {
        title: 'Nachrichten',
        path: Messages,
        component: import("module_components/Pages/Notifications/NotificationTable"),
        permissions: [adminRole, managerRole, userRole]
    },
    /*
        SIDEBAR URLS
    */
    {
        title: 'Dashboard',
        path: DashboardPath,
        component: import("pages/Basic/Dashboard/Dashboard"),
        section: "default",
        category: "sidebar",
        icon: "ni ni-spaceship eless-color",
        permissions: [adminRole, managerRole, userRole]
    },
    {
        title: 'Kalkulator',
        path: Calculator+Data,
        component: import("module_components/Pages/Calculation/Pages/CalculationData"),
        section: "calculation",
        category: "sidebar",
        icon: "ni ni-chart-bar-32 eless-color",
        permissions: [adminRole, managerRole, userRole]
    },
    {
        title: 'Kontakte',
        path: Contacts,
        component: import("pages/Basic/Customer/CustomersTable"),
        section: "calculation",
        category: "sidebar",
        icon: "ni ni-badge eless-color",
        permissions: [adminRole, managerRole, userRole]
    },
    {
        title: 'Aufträge',
        path: Contracts,
        component:import("module_components/Pages/Contracts/ContractsTable"),
        section: "management",
        category: "sidebar",
        show_data_count: "open_contracts",
        icon: "ni ni-archive-2 eless-color",
        permissions: [adminRole, managerRole, userRole]
    },
    {
        title: "Klärfälle",
        path: ClearingList,
        component: ClearingTableContainer,
        section: "management",
        show_data_count: "open_clearings",
        category: "sidebar",
        icon: "ni ni-chat-round eless-color",
        permissions: [adminRole, managerRole, userRole]
    },
    {
        title: 'Provisionsvorgaben',
        path: Marginsettings,
        component: import("pages/Administration/Marginsettings/Marginsettings"),
        section: "administration",
        category: "sidebar",
        icon: "ni ni-money-coins eless-color",
        permissions: [adminRole, managerRole],
        team_permissions: [teams[0]],
        required_agency: [rheingas]
    },
    {
        title: 'Nutzerverwaltung',
        path: UserManagement,
        component: import("module_components/Pages/UserManagement/UserManagementTable"),
        section: "administration",
        category: "sidebar",
        icon: "ni ni-single-02 eless-color",
        permissions: [adminRole, managerRole]
    },
    {
        title: 'Firmenprofil',
        path: Customized,
        component: import("pages/Administration/Customization/Customize"),
        section: "administration",
        category: "sidebar",
        icon: "ni ni-palette eless-color",
        permissions: [adminRole, managerRole, userRole]
    },
    /*{
        title: 'Support',
        path: Support,
        component: import("pages/Administration/Support/Support"),
        section: "administration",
        category: "sidebar",
        icon: "ni ni-support-16 eless-color",
        permissions: [adminRole, managerRole, userRole],
        exclude_agencies: [rheingas]
    },*/
    /*
       GUEST URLS
    */

    /*
        DEFAULT URLS
     */
    {
        title: 'Profile',
        path: Profile,
        component: import("pages/Header/Profile/Profile"),
        permissions: [adminRole, managerRole, userRole]
    },
    {
        title: 'Kalkulation',
        path: Calculation,
        component: import("pages/Administration/CalculationSettings/CalculationSettings"),
        permissions: [adminRole, managerRole, userRole]
    },
    {
        title: 'Kontakt Ansicht',
        path: Contactoverview+'/:id',
        component: import("pages/Basic/Customer/CustomerPage/CustomerPage"),
        permissions: [adminRole, managerRole, userRole]
    },
    {
        title: 'Kontakt Formular',
        path: Contact+'/:id',
        component: import("pages/Basic/Customer/CustomerForm/CustomerFormContainer"),
        permissions: [adminRole, managerRole, userRole]
    },
    {
        title: 'Lieferstelle Formular',
        path: ConsumptionPoint+'/:medium_type/:customer_id/:consumption_point_id',
        component: import("pages/Basic/Consumptionpoint/ConsumptionPointHandler"),
        permissions: [adminRole, managerRole, userRole]
    },
    {
        title: 'Kalkulator Vorausgewählt',
        path: Calculator+Data+'/:customer_id',
        component: import("module_components/Pages/Calculation/Pages/CalculationData"),
        permissions: [adminRole, managerRole, userRole]
    },
    // {
    //     title: 'Kalkulator Überprüfung',
    //     path: Calculator+Verification+'/:token/:customer_id/:contract_id/:doc_id',
    //     component: import("module_components/Pages/Calculation/Pages/CalculationHandler"),
    //     permissions: [adminRole, managerRole, userRole]
    // },
    // {
    //     title: 'Kalkulator Überprüfung',
    //     path: Calculator+Verification+'/:token',
    //     component: import("module_components/Pages/Calculation/Pages/CalculationHandler"),
    //     permissions: [adminRole, managerRole, userRole]
    // },
    {
        title: 'Vertrag',
        path: SingleContract+'/:contract_id',
        component: import("module_components/Pages/Contracts/ContractHandler"),
        permissions: [adminRole, managerRole, userRole]
    },
    /* HEADER DROPDOWN URLS */
    {
        title: "Profil",
        path: Profile,
        component: import("pages/Header/Profile/Profile"),
        category: "header",
        icon: "fas fa-user eless-color",
        permissions: [adminRole, managerRole, userRole],
        exclude_agencies: [],
    },
    {
        title: "Kalkulation",
        path: Calculation,
        component: import("pages/Administration/CalculationSettings/CalculationSettings"),
        category: "header",
        icon: "fas fa-calculator eless-color",
        permissions: [adminRole, managerRole, userRole],
        exclude_agencies: [rheingas]
    },
    //{
    //    title: "Abonnement",
    //    path: null,
    //    component: 'subscribtion',
    //    category: "header",
    //    icon: "fas fa-credit-card eless-color",
    //    permissions: [adminRole],
    //    exclude_agencies: [rheingas]
    //},
    //collapse example:
    //NOTE: collapse links shouldnt have icons due to css delivered by argon-dashboard
    // {
    //     title: 'Kalkulation',
    //     path: "",
    //     component: 'collapse',
    //     project: ["CONSULTANT_PROJECT"],
    //     section: "administration",
    //     category: "sidebar",
    //     icon: "ni ni-chart-bar-32 eless-color",
    //     permissions: [adminRole, managerRole, userRole],
    //     undercomponents: [
    //         {
    //             title: 'Nutzerverwaltung',
    //             path: UserManagement,
    //             project: ["CONSULTANT_PROJECT"],
    //             component: import("pages/Administration/UserManagement/UserManagementTable"),
    //             section: "administration",
    //             category: "sidebar",
    //             permissions: [adminRole, managerRole, userRole]
    //         },
    //         {
    //             title: 'Nutzerverwaltung',
    //             path: UserManagement,
    //             project: ["CONSULTANT_PROJECT"],
    //             component: import("pages/Administration/UserManagement/UserManagementTable"),
    //             section: "administration",
    //             category: "sidebar",
    //             permissions: [adminRole, managerRole, userRole]
    //         },
    //     ]
    // },
];

export const auth_urls = [
    /*
         GUEST URLS
   */
    {
        title: "",
        path: LoginPath,
        component: LoginContainer
    },
    {
        title: "",
        path: SetNewPasswordPath,
        component: SetNewPasswordContainer
    },
    {
        title: "",
        path: PasswordRecoveryPath,
        component: PasswordRecoveryContainer
    },
    {
        title: "",
        path: RegisterPath,
        component: RegisterContainer
    },
    {
        title: "",
        // path: SuccessPage+'/:offer_token/:customer_id/:contract_id/:contract_file_id/:employee_id/:consultant_id',
        path: SuccessPage+'/:token',
        component: import("module_components/Pages/Calculation/Pages/CalculationHandler"),
    },
    {
        name: "not-found",
        component: PageNotFound,
    }
];

export const guestUrls = [
    LoginPath,
    RegisterPath,
    PasswordRecoveryPath,
    SetNewPasswordPath,
    SuccessPage
];